import { LockFilled, PlusOutlined, UnlockOutlined } from '@ant-design/icons';
import { gql, useMutation } from '@apollo/client';
import { Card, Modal, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Btn } from '../../../../../../../../components/Button';
import { ErrorAlert } from '../../../../../../../../components/ErrorAlert';
import { useWizardNavigation } from '../../../../../../../../components/WizardNavigationProvider';
import {
  Action,
  CreateMitemSprintDocument,
  GetAccelerationMeetingDocument,
  MitemStatus,
  StartNewSprint_MitemSprintFragment,
} from '../../../../../../../../generated/graphql';
import { useClosestPeriods } from '../../../../../../../../hooks/useClosestPeriods';
import { showNotification } from '../../../../../../../../services/fetchNotificationProperties';
import { mitemStatusColorPairs } from '../../../../../../../../styleVars';
import { SkaTable } from '../../../components/SkaTable';
import { SprintDetails } from '../../../../../../sprint/summary/components/SprintDetails';
import { SprintMitemTableActions } from '../../../../../../sprint/summary/components/SprintMitemTableActions';
import { CreateMitemDrawer } from '../../../../../../sprint/planning/components/CreateMitemDrawer';
import { useAccelerationMeeting } from '../../../AccelerationMeetingProvider';
import { PortalButton } from '../../MeetingNavigationBar';
import { AddExistingMitemDrawer } from './AddExistingMitemDrawer';
import { MitemCard } from './components/MitemCard';
import { MitemLayout } from './components/MitemLayout';
import './StartNewSprint.less';
import { isPossibleToChangeArchiveState } from '../../../../../../sprint/common/services/useMitemArchivation';
import { useTeamPermissions } from '../../../../../../../../usePermissions';
import { TourLink } from '../../../../../../../../components/TourLink';

interface Props {
  currentSprint: StartNewSprint_MitemSprintFragment;
  teamId: string;
}

export const StartNewSprint = ({ currentSprint, teamId }: Props) => {
  const { t } = useTranslation();
  const { unlockStep, currentStepPath, goToNextStep } = useWizardNavigation();
  const { currentPeriod } = useClosestPeriods(currentSprint.teamId);
  const [showCreateMitemModal, setShowCreateMitemModal] = useState(false);
  const {
    sprintKeyActivityContext: { addSprintKeyActivity, removeSprintKeyActivity },
  } = useAccelerationMeeting();
  const [startSprintModalVisible, setStartSprintModalVisible] = useState(false);
  const { isAllowed: isAllowedToEdit } = useTeamPermissions({
    requestedAction: [
      {
        resource: 'accelerationMeeting',
        action: [Action.UPDATE, Action.CREATE],
      },
    ],
    teamId: teamId,
  });

  const [showAddExistingMitemModal, setShowAddExistingMitemModal] =
    useState(false);

  const [createSprint, { loading, error }] = useMutation(
    CreateMitemSprintDocument,
    {
      refetchQueries: [
        {
          query: GetAccelerationMeetingDocument,
          variables: { teamId: currentSprint.teamId },
        },
      ],
      onCompleted: () => {
        unlockStep(currentStepPath);
        setStartSprintModalVisible(false);
        showNotification('success', {
          message: t('StartNewSprint.started'),
        });
        goToNextStep();
      },
    }
  );

  const onSubmit = () => {
    const mitemIds = currentSprint.mitems?.map((m) => m.id) ?? [];
    createSprint({
      variables: {
        teamId: currentSprint.teamId,
        createMitemSprintInput: {
          mostImportantItemIds: mitemIds,
        },
      },
    });
  };

  const mitems = currentSprint.mitems ?? [];

  const locked = currentSprint.locked;
  const LockIcon = locked ? LockFilled : UnlockOutlined;

  return (
    <MitemLayout
      title={
        <div className="flx flx--ai-center">
          <div className="mr--l StartNewSprint__lockIcon">
            <LockIcon />
          </div>

          <Typography.Title level={3} className="mb--none mr--l">
            {t('StartNewSprint.title')}
          </Typography.Title>
          <TourLink
            intercomTarget="Start next sprint"
            engTourId={'3892'}
            sweTourId={'3893'}
          />
          <PortalButton
            actionButton={
              <Btn
                type="callToAction"
                id="startNewSprint"
                disabled={locked}
                onClick={() => setStartSprintModalVisible(true)}
              >
                {locked
                  ? t('StartNewSprint.locked')
                  : t('StartNewSprint.saveButtonText')}
              </Btn>
            }
          />
        </div>
      }
      sidebar={
        <Card className="mt--xxl" title={t('StartNewSprint.commitCardTitle')}>
          <SprintDetails sprint={currentSprint} />
        </Card>
      }
    >
      <MitemCard
        title={
          locked
            ? t('common.committed', { count: mitems.length })
            : t('StartNewSprint.planned')
        }
        titleColors={
          locked ? mitemStatusColorPairs.ACTIVE : mitemStatusColorPairs.PLANNED
        }
        actions={
          <div>
            <Btn
              className="mr--s"
              onClick={() => {
                setShowAddExistingMitemModal(true);
              }}
              icon={<PlusOutlined />}
            >
              {t('StartNewSprint.addExisting')}
            </Btn>
            <Btn
              onClick={() => {
                setShowCreateMitemModal(true);
              }}
              icon={<PlusOutlined />}
            >
              {t('StartNewSprint.createMitem')}
            </Btn>
          </div>
        }
      >
        <SkaTable
          mitems={mitems}
          pagination={false}
          teamId={teamId}
          actionColumn={
            isAllowedToEdit
              ? {
                  title: t('common.actions') as string,
                  dataIndex: 'actions',
                  key: 'actions',
                  render: (_, record) => {
                    if (record.__typename === 'Mitem')
                      return (
                        <SprintMitemTableActions
                          teamId={teamId}
                          mitem={record}
                          isLockedSprint={locked}
                          isAllowedToArchive={
                            record.__typename === 'Mitem' && currentSprint
                              ? isPossibleToChangeArchiveState(
                                  record,
                                  currentSprint
                                )
                              : false
                          }
                          editable={
                            record.__typename === 'Mitem' &&
                            !(
                              record.status === MitemStatus.NOT_COMPLETED ||
                              record.status === MitemStatus.ARCHIVED
                            )
                          }
                          refetchQueries={[
                            {
                              query: GetAccelerationMeetingDocument,
                              variables: { teamId: currentSprint.teamId },
                            },
                          ]}
                          onSprintKeyActivityArchived={(mitem) =>
                            removeSprintKeyActivity(mitem.id)
                          }
                          onCopied={(sprintKaId, ownerId) => {
                            addSprintKeyActivity(sprintKaId, ownerId);
                          }}
                        />
                      );
                  },
                }
              : undefined
          }
        />
      </MitemCard>
      <AddExistingMitemDrawer
        teamId={currentSprint.teamId}
        showModal={showAddExistingMitemModal}
        refetchQueries={[
          {
            query: GetAccelerationMeetingDocument,
            variables: { teamId: currentSprint.teamId },
          },
        ]}
        onCancel={() => {
          setShowAddExistingMitemModal(false);
        }}
      />
      <CreateMitemDrawer
        teamId={currentSprint.teamId}
        showHelpLinks={false}
        showModal={showCreateMitemModal}
        onCancel={() => {
          setShowCreateMitemModal(false);
        }}
        onCompleted={(sprintKeyActivity) => {
          addSprintKeyActivity(
            sprintKeyActivity.id,
            sprintKeyActivity.owner.id
          );
          setShowCreateMitemModal(false);
        }}
        prefilledValues={{ deadline: currentPeriod?.timePeriodEndDate }}
        refetchQueries={[
          {
            query: GetAccelerationMeetingDocument,
            variables: { teamId: currentSprint.teamId },
          },
        ]}
      />
      <Modal
        open={startSprintModalVisible}
        onCancel={() => setStartSprintModalVisible(false)}
        okText={t('StartNewSprint.commit')}
        title={
          <div className="flx ">
            <div className="StartNewSprint__ModalTitle mr">
              <LockIcon />
            </div>

            {t('StartNewSprint.commitToSprint')}
          </div>
        }
        onOk={onSubmit}
        confirmLoading={loading}
      >
        {t('StartNewSprint.modalContent')}
        <ErrorAlert
          error={error}
          title={t('StartNewSprint.createSprintError')}
        />
      </Modal>
    </MitemLayout>
  );
};

// this is used indirectly - `CreateMitemSprintDocument` is generated from it
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CREATE_MITEM_SPRINT = gql`
  mutation createMitemSprint(
    $teamId: ID!
    $createMitemSprintInput: CreateMitemSprintInput!
  ) {
    createMitemSprint(
      teamId: $teamId
      createMitemSprintInput: $createMitemSprintInput
    ) {
      sprint {
        id
        locked
        startDate
        endDate
        sprintNumber
        teamId
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const START_NEW_PRINT_FRAGMENT = gql`
  fragment StartNewSprint_MitemSprint on MitemSprint {
    id
    teamId
    locked
    startDate
    endDate
    finalized
    mitems {
      id
      name
      status
      deadline
      ...SkaTable_Mitem
      ...SprintMitemTableActions_Mitem
    }
  }
`;
