import { gql } from '@apollo/client';
import React from 'react';
import {
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ResponsiveContainer,
} from 'recharts';
import { MigReportGraph_MigV2Fragment } from '../../../../generated/graphql';
import { Colors } from '../../../componentLibrary/Colors';
import { CustomAxisTick } from '../../../../services/GraphTickHelper';
import { friendlyDate } from '../../../../services/dateFormats';
import './MigReportGraph.less';

interface Props {
  mig: MigReportGraph_MigV2Fragment;
}

const GoalChart = ({ mig }: Props) => {
  const data = mig.periodData.map((p) => ({
    ...p,
    label: friendlyDate(p.targetDate),
  }));
  return (
    <div style={{ textAlign: 'center' }} id="mig-report-graph">
      <ResponsiveContainer width="99%" height={300}>
        <AreaChart
          data={data}
          margin={{ top: 20, right: 30, left: -10, bottom: 20 }}
        >
          <defs>
            <linearGradient id="colorGoal" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={Colors.Howwe.MAIN_ORANGE}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={Colors.Howwe.MAIN_ORANGE}
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id="colorActual" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={Colors.Action.BLUE}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={Colors.Action.BLUE}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis
            dataKey="targetDate"
            tick={<CustomAxisTick lineData={data} />}
          />
          <YAxis domain={[mig.minY ?? 'dataMin', mig.maxY ?? 'dataMax']} />
          <Tooltip />
          {/* <Legend content={CustomLegend} /> */}
          {/* <ReferenceLine x="Jan" stroke="gray" strokeDasharray="3 3" /> */}

          {/* Shaded Area */}
          <Area
            type="monotone"
            dataKey="goal"
            stroke={Colors.Howwe.MAIN_ORANGE}
            fill="url(#colorGoal)"
            fillOpacity={0.2}
          />

          <Area
            type="monotone"
            dataKey="actual"
            stroke={Colors.Action.BLUE}
            fill="url(#colorActual)"
            fillOpacity={0.2}
          />

          {/* Goal Line */}
          <Line
            type="monotone"
            dataKey="actual"
            stroke={Colors.Action.BLUE}
            strokeWidth={2}
          />

          {/* Outcome Line */}
          <Line
            type="monotone"
            dataKey="goal"
            stroke={Colors.Howwe.MAIN_ORANGE}
            strokeWidth={2}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GoalChart;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MIG_REPORT_GRAPH_FRAGMENT = gql`
  fragment MigReportGraph_MigV2 on MigV2 {
    id
    domainId {
      teamId
      itemId
    }
    periodData {
      targetDate
      actual
      goal
      historic
    }
    name
    unit
    mostRecentReport {
      targetDate
      actual
      goal
      goalFulfillment
      historic
    }
    minY
    maxY
  }
`;
