import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useMemo } from 'react';
import { Divider } from 'antd';
import { ReportSection } from '../ReportSection';
import { GigantishReportSection } from './GigantishReportSection';
import { SankeyProfitRevenueChart } from './SankeyProfitRevenueChart';
import { InitiativesBubbleChart } from './InitiativesBubbleChart';
import { InitiativeCardSection } from './InitiativeCardSection/InitiativeCardSection';
import {
  GetInitiativeDetailedReport2ForPdfDocument,
  GetTopInitiativesPdfReport2Document,
  InitiativeDetailedReportResponseFragment,
} from '../../../../generated/graphql';
import { InitiativeTag } from '../../../../components/initiative/InitiativeTag';

export const CompanyInitiativeReportSection = () => {
  const { t } = useTranslation();
  const { data } = useQuery(GetTopInitiativesPdfReport2Document);
  const [fetchInitiative] = useLazyQuery(
    GetInitiativeDetailedReport2ForPdfDocument
  );

  const [initiativeReports, setInitiativeReports] = useState<
    InitiativeDetailedReportResponseFragment[]
  >([]);

  const companyInitiatives = useMemo(
    () =>
      data?.topInitiativeReport2.initiatives.filter(
        (i) => i.companyInitiative
      ) || [],
    [data]
  );

  useEffect(() => {
    if (companyInitiatives.length > 0) {
      const fetchAllReports = async () => {
        const results = [];
        for (const initiative of companyInitiatives) {
          const response = await fetchInitiative({
            variables: { initiativeId: initiative.domainId.itemId },
          });
          response.data?.initiativeDetailedReport &&
            results.push(response.data?.initiativeDetailedReport);
        }
        results && setInitiativeReports(results);
      };

      fetchAllReports();
    }
  }, [companyInitiatives, fetchInitiative]);

  return (
    <ReportSection
      isPreviewMode={false}
      className="page-break"
      title={t('CompanyInitiativeReportSection.title')}
      subTitle={t('CompanyInitiativeReportSection.subTitle')}
    >
      <InitiativeCardSection companyInitiatives={companyInitiatives} />
      <div className="page-break">
        <GigantishReportSection />
      </div>
      <Divider className="exclude-from-pdf" />
      <div className="page-break pt--xl flx vertically-center">
        <div className="flx--3 mr--l">
          <SankeyProfitRevenueChart initiatives={companyInitiatives} />
        </div>
        <div className="flx--3">
          {initiativeReports.length > 0 && (
            <InitiativesBubbleChart
              initiativesDetails={initiativeReports}
              initiatives={companyInitiatives.map((ci) => ci.initiative)}
            />
          )}
        </div>
        <div
          className="flx--1"
          style={{ marginTop: 'auto', marginBottom: 'auto' }}
        >
          <div>
            {companyInitiatives.map((ci) => (
              <div key={ci.id} className="mb">
                <InitiativeTag tag={ci.initiative.tag} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </ReportSection>
  );
};

// GraphQL queries
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_INITIATIVES_SANKEY = gql`
  query getTopInitiativesPdfReport2(
    $tenantId: ID
    $filter: TopInitiativeFilter
  ) {
    topInitiativeReport2(tenantId: $tenantId, filter: $filter) {
      initiatives {
        id
        domainId {
          itemId
          tenantId
        }
        companyInitiative
        accumulatedStats {
          milestones {
            completed
            overdue
            upcoming
          }
        }
        initiative {
          id
          name
          description
          domainId {
            itemId
            tenantId
          }
          tag {
            iconId
            colorCode
            title
          }
          profit {
            potential
            current
          }
          revenue {
            potential
            current
          }
          metadata {
            status
          }
          ...InitiativesBubbleChart__InitiativeLight
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TENANT_INITIATIVES = gql`
  query getInitiativeDetailedReport2ForPdf($tenantId: ID, $initiativeId: ID!) {
    initiativeDetailedReport(tenantId: $tenantId, initiativeId: $initiativeId) {
      id
      ...InitiativeDetailedReportResponse
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_INITIATIVE_DETAILED_REPORT = gql`
  fragment InitiativeDetailedReportResponse on InitiativeDetailedReportResponse {
    id
    domainId {
      itemId
      tenantId
    }
    teamStats {
      team {
        id
        name
        domainId {
          itemId
        }
      }
    }
  }
`;
