import { Suspense } from 'react';
import { Gigantish } from '../../strategicPlan/components/Gigantish/Gigantish';
import { Divider } from 'antd';

export const GigantishReportSection = () => {
  return (
    <div>
      <Divider />
      <h3>Strategic plan overview</h3>
      <Suspense fallback={<Gigantish.skeleton />}>
        <Gigantish />
      </Suspense>
    </div>
  );
};
