import { Sankey, Tooltip } from 'recharts';
import Node from './SankeyProfitRevenueChart/Node';
import Link from './SankeyProfitRevenueChart/Link';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { SankeyProfitRevenueChart__TopInitiativeReportResponse2Fragment } from '../../../../generated/graphql';

const PROFIT = 0;
const REVENUE = 1;

interface Props {
  initiatives: SankeyProfitRevenueChart__TopInitiativeReportResponse2Fragment[];
}

export const SankeyProfitRevenueChart = ({ initiatives }: Props) => {
  const { t } = useTranslation();

  const nodes = [
    { name: t('SankeyProfitRevenueChart.revenue') },
    { name: t('SankeyProfitRevenueChart.profit') },
  ].concat(
    initiatives.map((initiative) => ({
      name: initiative.initiative.tag.title,
      profit: initiative.initiative.profit?.potential ?? '',
      revenue: initiative.initiative.revenue?.potential ?? '',
      color: initiative.initiative.tag.colorCode,
    }))
  );

  const links =
    initiatives.flatMap((initiative, index) => {
      const linksArray = [];

      // Check and add profit link if the potential profit value is set
      if (initiative.initiative.profit?.potential) {
        linksArray.push({
          source: PROFIT,
          target: index + 2,
          value: initiative.initiative.profit.potential,
          color: initiative.initiative.tag.colorCode,
        });
      }

      // Check and add revenue link if the potential revenue value is set
      if (initiative.initiative.revenue?.potential) {
        linksArray.push({
          source: REVENUE,
          target: index + 2,
          value: initiative.initiative.revenue.potential,
          color: initiative.initiative.tag.colorCode,
        });
      }

      return linksArray;
    }) ?? [];

  return (
    <div>
      <h3>{t('SankeyProfitRevenueChart.financialPotential')}</h3>
      <div className="txt--secondary mb--l">
        {t('SankeyProfitRevenueChart.description')}
      </div>
      <Sankey
        width={380}
        height={360}
        margin={{ top: 20, bottom: 20 }}
        data={{ nodes, links }}
        nodeWidth={10}
        nodePadding={40}
        linkCurvature={0.61}
        iterations={64}
        link={<Link />}
        node={<Node containerWidth={300} />}
      >
        <defs>
          <linearGradient id={'linkGradient'}>
            <stop offset="0%" stopColor="rgba(0, 136, 254, 0.5)" />
            <stop offset="100%" stopColor="rgba(0, 197, 159, 0.3)" />
          </linearGradient>
        </defs>
        <Tooltip />
      </Sankey>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SANKEY_PROFIT_REVENUE_CHART = gql`
  fragment SankeyProfitRevenueChart__TopInitiativeReportResponse2 on TopInitiativeReport2 {
    id
    domainId {
      itemId
      tenantId
    }
    companyInitiative
    accumulatedStats {
      milestones {
        completed
        overdue
        upcoming
      }
    }
    initiative {
      id
      name
      description
      domainId {
        itemId
        tenantId
      }
      tag {
        iconId
        colorCode
        title
      }
      profit {
        potential
        current
      }
      revenue {
        potential
        current
      }
      metadata {
        status
      }
    }
  }
`;
