import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as d3 from 'd3';
import { gql } from '@apollo/client';
import {
  InitiativesBubbleChart__InitiativeDetailedReportResponseFragment,
  InitiativesBubbleChart__InitiativeLightFragment,
} from '../../../../generated/graphql';

// Define the types for the data
interface DataPoint extends d3.SimulationNodeDatum {
  value: number;
  radius: number; // Add radius to the data
  icon: string;
  color: string;
}

interface Props {
  initiativesDetails: InitiativesBubbleChart__InitiativeDetailedReportResponseFragment[];
  initiatives: InitiativesBubbleChart__InitiativeLightFragment[];
}

export const InitiativesBubbleChart = ({
  initiativesDetails,
  initiatives,
}: Props) => {
  const { t } = useTranslation();
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    const width = 400;
    const height = 400;

    // Set up the SVG canvas
    const svg = d3
      .select(svgRef.current)
      .attr('width', width)
      .attr('height', height)
      .style('background-color', '#fff');

    // Scale for radius based on value
    const radiusScale = d3
      .scaleSqrt()
      .domain([
        0,
        Math.max(...initiativesDetails.map((d) => d.teamStats.length)),
      ]) // scale for radius based on the maximum value
      .range([10, 50]); // set range of bubble sizes

    // Add radius to each data point
    const bubbles: DataPoint[] = initiativesDetails.map((d) => {
      const initiative = initiatives.find(
        (i) => i.domainId.itemId === d.domainId.itemId
      );
      return {
        ...d,
        icon: initiative?.tag.title ?? '',
        value: d.teamStats.length,
        color: initiative?.tag.colorCode ?? '#8884d8',
        radius: radiusScale(d.teamStats.length), // Calculate radius based on value
      };
    });

    // Set up the force simulation
    d3.forceSimulation(bubbles)
      .force('charge', d3.forceManyBody().strength(-10)) // Repulsive force between circles
      .force('center', d3.forceCenter(width / 2, height / 2)) // Center the bubbles in the SVG
      .force(
        'collide',
        d3.forceCollide().radius((d) => (d as DataPoint).radius + 5)
      ) // Avoid overlap with a slight margin
      .on('tick', () => {
        // Update positions on each simulation tick
        svg
          .selectAll('circle')
          .attr('cx', (d) => (d as DataPoint).x!)
          .attr('cy', (d) => (d as DataPoint).y!);

        svg
          .selectAll('text')
          .attr('x', (d) => (d as DataPoint).x!)
          .attr('y', (d) => (d as DataPoint).y!);
      });

    // Create circles for each data point
    svg
      .selectAll('circle')
      .data(bubbles)
      .enter()
      .append('circle')
      .attr('r', (d: DataPoint) => d.radius) // Set radius based on the value
      .attr('fill', (d: DataPoint) => d.color)
      .attr('opacity', 0.7)
      .attr('stroke', 'white')
      .attr('stroke-width', 2);

    // Add icons inside the bubbles (could be an emoji or SVG icon)
    svg
      .selectAll('text')
      .data(bubbles)
      .enter()
      .append('text')
      .attr('x', (d: DataPoint) => d.x ?? 0)
      .attr('y', (d: DataPoint) => d.y ?? 0)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr('font-size', '10px')
      .text((d: DataPoint) => d.value);
  }, []);

  return (
    <div>
      <h3>{t('InitiativesBubbleChart.teamEngagements')}</h3>
      <div className="txt--secondary mb--l">
        {t('InitiativesBubbleChart.chartDescription')}
      </div>

      <svg ref={svgRef}></svg>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_HEADER_SECTION__INITIATIVE_DETAILED_REPORT_RESPONSE = gql`
  fragment InitiativesBubbleChart__InitiativeDetailedReportResponse on InitiativeDetailedReportResponse {
    id
    domainId {
      itemId
      tenantId
    }
    teamStats {
      team {
        id
        name
        domainId {
          itemId
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_BUBBLE_CHART = gql`
  fragment InitiativesBubbleChart__InitiativeLight on InitiativeLight {
    id
    name

    domainId {
      itemId
      tenantId
    }
    tag {
      iconId
      colorCode
      title
    }
    metadata {
      status
    }
  }
`;
