import { useTranslation } from 'react-i18next';
import { Action } from '../../../../../generated/graphql';
import { Input, Select } from 'antd';
import { MilestoneOrActiveToggleEnum } from '../milestoneOrActivityToggle/MilestoneOrActiveToggle';

import { PermissionChecker } from '../../../../../PermissionChecker';

import { ProgressSectionContentSkeleton } from './ProgressSectionContentSkeleton';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { MilestoneOrActiveToggleSkeleton } from '../milestoneOrActivityToggle/MilestoneOrActiveToggle.skeleton';
import { Btn } from '../../../../../components/Button';

export const ProgressSectionSkeleton = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="mb--xl mt flx">
        <h3>{t('ProgressSection.title')}</h3>
        <div className="ml--auto mr">
          <PermissionChecker
            resourceOwner={{
              type: 'TenantResource',
              requestedAction: {
                resource: 'milestone',
                action: Action.CREATE,
              },
            }}
          >
            <Btn disabled icon={<PlusOutlined />}>
              {t('ProgressSection.createMilestone')}
            </Btn>
          </PermissionChecker>
        </div>
        <div>
          <Btn icon={<PlusOutlined />} disabled>
            {t('ProgressSection.createKeyActivity')}
          </Btn>
        </div>
      </div>
      <div className="mb--xl mt flx flx--jc-space-between flx--ai-center">
        <Labeled text={t('ProgressSection.resolution')}>
          <MilestoneOrActiveToggleSkeleton />
        </Labeled>

        <div className="flx">
          <Labeled text={t('ProgressSection.initiativeAlignment')}>
            <Select
              placeholder="Filter by initiative"
              allowClear
              className="mr--xl"
              disabled
              style={{ width: 250 }}
            />
          </Labeled>
          <Labeled text={t('ProgressSection.search')}>
            <Input
              prefix={<SearchOutlined className="txt--secondary" />}
              placeholder={t('ProgressSection.searchPlaceholder')}
              allowClear
              disabled
              style={{ width: 250 }}
            />
          </Labeled>
        </div>
      </div>
      <ProgressSectionContentSkeleton
        milestonesOrActivities={MilestoneOrActiveToggleEnum.Milestones}
      />
    </div>
  );
};

const Labeled = ({
  text,
  children,
}: {
  text: string;
  children: React.ReactNode;
}) => {
  return (
    <div>
      <div className="txt--secondary font-size--sm">{text}</div>
      {children}
    </div>
  );
};
