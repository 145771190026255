import { Divider } from 'antd';
import { useState } from 'react';
import './ReportSection.less';

interface Props {
  isPreviewMode: boolean;
  title: string;
  subTitle?: string;
  children?: React.ReactNode;
  className?: string;
}

export const ReportSection = ({
  isPreviewMode,
  title,
  subTitle,
  children,
  className,
}: Props) => {
  const [excludeSection, setExcludeSection] = useState(false);

  return (
    <div className={className}>
      <div className="center-content">
        <h1 className="mb--xs">{title}</h1>
        {subTitle && <h4 className="txt--secondary mb">{subTitle}</h4>}
      </div>
      {!isPreviewMode && (
        <div
          className="link center-content"
          onClick={() => setExcludeSection(!excludeSection)}
        >
          {excludeSection ? 'Include whole section' : 'Exclude whole section'}
        </div>
      )}
      <div className="mb--l exclude-from-pdf">
        <Divider />
      </div>

      {!excludeSection && children}
    </div>
  );
};
