import { Checkbox, Table, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { MitemFilterStatus } from '../../../../sprint/common/components/mitemFilters/StatusFilter';
import '../../../../sprint/summary/components/SprintMitemTable.less';
import { mitemStatusColor } from '../../../../../../styleVars';
import { recordDefaultSortOrder } from '../../../../../../services/sprintKeyActivityUtils';
import { DisplayName } from '../../../../../../components/DisplayName';
import { stringSort } from '../../../../../../services/stringSort';
import { friendlyUsername } from '../../../../../../services/friendlyUsername';
import {
  MitemActionType,
  MitemDetails_MitemFragment,
  SkaTable_MitemFragment,
  SprintMitemTable_MilestoneFragment,
} from '../../../../../../generated/graphql';
import { MitemHistory } from '../../../../sprint/common/components/MitemHistory';
import { DisplayDate } from '../../../../../../components/DisplayDate';
import { ExpandArrow } from '../../../../../../components/ExpandArrow';
import {
  migFilterCompare,
  ownerFilterCompare,
  statusFilterCompare,
} from '../../../../sprint/common/hooks/useMitemFilters';
import { CommitColumn } from '../../../../sprint/summary/components/sprintMitemTable/CommitColumn';
import { dateCompare } from '../../../../../../services/dateHelpers';
import { TagImage } from '../../../../setup/overviewPage/labels/TagImage';
import { InitiativeTag } from '../../../../../../components/initiative/InitiativeTag';
import {
  Icons,
  InitiativeIcon,
} from '../../../../../company/initiatives_old/initiativesPageV1/InitiativeIcons';
import { StarIcon } from '../../../../../../icons/Star';
import { MinusOutlined } from '@ant-design/icons';
import { Colors } from '../../../../../componentLibrary/Colors';
import { gql } from '@apollo/client';
import { useAccelerationMeeting } from '../AccelerationMeetingProvider';
import { Suspense, useMemo } from 'react';
import { StatusTag } from '../../../../../../components/StatusTag';
import { useTenantDetails } from '../../../../../../hooks/useTenantDetails';
import { match } from 'ts-pattern';
import { ErrorBoundary } from 'react-error-boundary';
import { ProgressStats } from '../../../../../../components/initiatives/ProgressStats';
import { MilestoneCardActivityStats } from '../../../../sprint/planning/components/mitemPlanningBoard/milestoneCard/MilestoneCardActivityStats';
import { MilestoneCardActivityTab } from '../../../../../company/initiatives/initiativeDetails/progressSection/components/milestoneTable/components/milestoneActions/components/MilestoneCardActivityTab';

export type MitemRescheduleAction = {
  action: MitemActionType.PLANNED;
  date: string;
};

export type MitemCompleteAction = {
  action: MitemActionType.COMPLETED;
};

export type MitemDeleteAction = {
  action: MitemActionType.ARCHIVED;
};

export type MitemAction =
  | MitemRescheduleAction
  | MitemDeleteAction
  | MitemCompleteAction;

interface Props<T extends SkaTable_MitemFragment> {
  mitems?: T[] | null;
  milestones?: SprintMitemTable_MilestoneFragment[] | null;
  loading?: boolean;
  filters?: { ownerId?: string; status?: MitemFilterStatus; migId?: string };
  actionColumn?: ColumnProps<T | SprintMitemTable_MilestoneFragment> | null;
  pagination?: boolean;
  includeStatus?: boolean;
  committable?: boolean;
  teamId: string;
  mitemActions?: {
    [mitemId: string]: MitemAction | null;
  };
}

interface ExcludedColumnProps<T> extends ColumnProps<T> {
  excluded?: boolean;
}

export const SkaTable = <T extends SkaTable_MitemFragment>({
  mitems,
  loading,
  filters,
  actionColumn,
  pagination = false,
  includeStatus = true,
  committable = false,
  mitemActions,
  teamId,
  milestones,
}: Props<T>) => {
  const { features } = useTenantDetails();
  const { t } = useTranslation();

  const { sprintKeyActivityContext } = useAccelerationMeeting();
  const numberOfCommitted = useMemo(() => {
    return Object.values(sprintKeyActivityContext.commitments).filter(
      (c) =>
        c.commitment.committed && mitems?.some((m) => m.id === c.sprintKaId)
    ).length;
  }, [mitems, sprintKeyActivityContext.commitments]);

  const filteredMitems = mitems
    ?.filter((m) => ownerFilterCompare(m.owner.id, filters?.ownerId))
    .filter((m) => statusFilterCompare(m.status, filters?.status))
    .filter((m) =>
      migFilterCompare(
        m.supportedMigs.map((m) => m.domainId.itemId) ?? [],
        filters?.migId
      )
    );

  const tableDataToBeFiltered = [
    ...(filteredMitems ?? []),
    ...(milestones?.filter((m) => m.metadata.archived == false) ?? []),
  ];

  type SprintMitemTableColumnType = T | SprintMitemTable_MilestoneFragment;

  const sprintMitemColumns: ExcludedColumnProps<SprintMitemTableColumnType>[] =
    [
      {
        title: t('common.sprintKeyActivity.status'),
        excluded: !includeStatus,
        width: 90,
        sorter: (a, b) => {
          const statusA =
            a.__typename === 'Milestone' ? a.metadata.status : a.status;
          const statusB =
            b.__typename === 'Milestone' ? b.metadata.status : b.status;
          return (
            recordDefaultSortOrder.indexOf(statusA) -
            recordDefaultSortOrder.indexOf(statusB)
          );
        },
        render: (_, record) => {
          return (
            <div className="flx flx--column dimmable">
              {record.__typename === 'Milestone' ? (
                <StatusTag status={record.metadata.status} />
              ) : (
                <StatusTag status={record.status} />
              )}
            </div>
          );
        },
      },
      {
        title: t('common.title'),
        dataIndex: 'name',
        sorter: (a, b) => stringSort(a.name, b.name),
        render: (_, record) => {
          return (
            <div className="dimmable">
              <Typography.Text>{record.name}</Typography.Text>
              {record.__typename === 'Milestone' && (
                <ErrorBoundary
                  FallbackComponent={MilestoneCardActivityStats.Error}
                >
                  <Suspense fallback={<ProgressStats.Skeleton />}>
                    {teamId && (
                      <div className="flx txt--secondary flx--ai-center">
                        <div className="mr">
                          {t('SprintMitemTable.activityProgress')}
                        </div>
                        <MilestoneCardActivityStats
                          milestoneId={record.domainId.itemId}
                          teamId={teamId}
                        />
                      </div>
                    )}
                  </Suspense>
                </ErrorBoundary>
              )}
            </div>
          );
        },
      },
      {
        title: t('common.sprintKeyActivity.owner'),
        dataIndex: ['owner', 'id'],
        sorter: (a, b, sortOrder) => {
          const ownerA =
            a.__typename === 'Milestone' ? a.assignedTo[0] : a.owner;
          const ownerB =
            b.__typename === 'Milestone' ? b.assignedTo[0] : b.owner;

          const ownerSort = stringSort(
            friendlyUsername(ownerA),
            friendlyUsername(ownerB)
          );
          const deadlineA =
            a.__typename === 'Milestone' ? a.deadlineAt : a.deadline;
          const deadlineB =
            b.__typename === 'Milestone' ? b.deadlineAt : b.deadline;
          const deadlineSort = dateCompare(deadlineA, deadlineB);
          const deadlineDirection = sortOrder === 'descend' ? -1 : 1;

          return ownerSort || deadlineSort * deadlineDirection;
        },
        render: (_, record) => {
          if (record.__typename === 'Milestone') {
            return (
              <div className="dimmable">
                <DisplayName user={record.assignedTo[0]} />
              </div>
            );
          }
          return (
            <div className="dimmable">
              <DisplayName user={record.owner} />
            </div>
          );
        },
      },
      {
        title: t('common.sprintKeyActivity.deadline'),
        sorter: (a, b) => {
          const deadlineA =
            a.__typename === 'Milestone' ? a.deadlineAt : a.deadline;
          const deadlineB =
            b.__typename === 'Milestone' ? b.deadlineAt : b.deadline;
          const deadlineSort = dateCompare(deadlineA, deadlineB);
          const ownerA =
            a.__typename === 'Milestone' ? a.assignedTo[0] : a.owner;
          const ownerB =
            b.__typename === 'Milestone' ? b.assignedTo[0] : b.owner;
          const ownerSort = stringSort(
            friendlyUsername(ownerA),
            friendlyUsername(ownerB)
          );
          return deadlineSort || ownerSort;
        },
        defaultSortOrder: 'ascend',
        dataIndex: 'deadline',
        render: (deadline, record) => {
          const formattedDeadline = (
            <div className="dimmable">
              {record.__typename === 'Milestone' ? (
                <DisplayDate date={record.deadlineAt} />
              ) : (
                <DisplayDate date={record.deadline} />
              )}
            </div>
          );
          if (record.__typename === 'Mitem') {
            const handledMitem = mitemActions?.[record.id];
            if (handledMitem?.action === MitemActionType.PLANNED) {
              return (
                <div className={'flx flx--column'}>
                  <Typography.Text type="secondary" className="lineThrough">
                    {formattedDeadline}
                  </Typography.Text>
                  <div style={{ color: mitemStatusColor.PLANNED }}>
                    <DisplayDate date={handledMitem.date} />
                  </div>
                </div>
              );
            }
          }
          return formattedDeadline;
        },
      },
      {
        title: <StarIcon title={t('common.sprintKeyActivity.milestone')} />,
        width: 48,
        dataIndex: 'milestone',
        excluded: features.tenantInitiativesEnabled,
        render(_, record) {
          if (record.__typename === 'Mitem') {
            return (
              <span style={{ fontSize: 16 }}>
                {record.milestone ? (
                  <StarIcon />
                ) : (
                  <MinusOutlined
                    style={{ color: Colors.Grays.FRAMES_AND_LINES }}
                  />
                )}
              </span>
            );
          }
        },
      },
      {
        title: t('common.sprintKeyActivity.tags'),
        render: (_, record) => {
          let tags = [] as React.ReactNode[];
          if (record.__typename === 'Mitem') {
            const sortedTeamTags =
              sortTeamTags(record).map((t) => (
                <TagImage key={t.id} tag={t} />
              )) ?? [];

            const sortedInitiativeTags =
              sortInitiativeTags(features, record).map(
                ({ id, tag, metadata }) => (
                  <InitiativeTag
                    key={id}
                    title={tag.title}
                    borderColor={tag.colorCode}
                    completed={metadata.completedAt !== null}
                    icon={Icons[tag.iconId as InitiativeIcon]}
                    archived={metadata.archived}
                  />
                )
              ) ?? [];

            tags = [...sortedInitiativeTags, ...sortedTeamTags];
          }
          if (record.__typename === 'Milestone') {
            tags =
              record.metadata.supports
                ?.filter((si) => si.__typename === 'SupportedInitiative')
                .map((si) => (
                  <InitiativeTag
                    key={si.item.id}
                    title={si.item.tag.title}
                    borderColor={si.item.tag.colorCode}
                    completed={si.item.metadata.completedAt !== null}
                    icon={Icons[si.item.tag.iconId as InitiativeIcon]}
                  />
                )) ?? [];
          }
          return (
            <div className="flx flx--gap--xs flx--wrap dimmable">
              {tags.length > 0 ? tags : <span>-</span>}
            </div>
          );
        },
      },

      {
        title: (
          <CommitmentColumnHeader
            numberOfSka={mitems?.length ?? 0}
            numberOfCommitted={numberOfCommitted}
            onToggleAll={(checked: boolean) =>
              sprintKeyActivityContext.toggleAllCommitments(
                checked,
                mitems ?? []
              )
            }
          />
        ),
        defaultSortOrder: 'ascend',
        excluded: !committable,
        dataIndex: 'commitment',
        render: (_, mitem) => {
          if (mitem.__typename === 'Mitem') {
            return (
              committable && (
                <CommitColumn sprintKaId={mitem.id} ownerId={mitem.owner.id} />
              )
            );
          }
        },
      },
      ...(actionColumn ? [actionColumn] : []),
      Table.EXPAND_COLUMN,
    ];

  return (
    <Table
      className="SprintMitemTableCard__mitemTable"
      columns={sprintMitemColumns.filter((c) => !c.excluded)}
      dataSource={tableDataToBeFiltered ?? []}
      expandable={{
        expandRowByClick: true,
        expandedRowRender: (record) => {
          if (record.__typename === 'Milestone') {
            return (
              <Suspense
                fallback={<MilestoneCardActivityTab.Skeleton teamId={teamId} />}
              >
                <MilestoneCardActivityTab
                  milestoneId={record.domainId.itemId}
                  teamId={teamId}
                />
              </Suspense>
            );
          }
          return <MitemDetails mitem={record} />;
        },
        expandIcon: ({ expanded, onExpand, record }) => (
          <ExpandArrow
            onClick={(e) => onExpand(record, e)}
            expanded={expanded}
          />
        ),
      }}
      loading={loading}
      pagination={!pagination ? false : { size: 'small' }}
      rowKey={(mitem) => mitem.id}
      rowHoverable={false}
      rowClassName={(record) => {
        if (record.__typename === 'Milestone') {
          // Add status class to milestone rows
          return `SprintMitemTable__row__milestone SprintMitemTable__row__milestone--${record.metadata.status}`;
        }
        return '';
      }}
    />
  );
};

const sortTeamTags = (mitem: SkaTable_MitemFragment) => {
  const teamTags = mitem?.tags ?? [];

  const sortedTeamTags = [...teamTags].sort((a, b) => {
    return stringSort(a.name, b.name);
  });
  return sortedTeamTags;
};

const sortInitiativeTags = (
  features: {
    teamInitiativesEnabled: boolean;
    tenantInitiativesEnabled: boolean;
  },
  mitem: SkaTable_MitemFragment
) => {
  const supportedInitiatives = match(features)
    .with({ teamInitiativesEnabled: true }, () => {
      return (
        mitem.supportedInitiatives?.map((teamInitiative) => {
          return {
            id: teamInitiative.id,
            tag: teamInitiative.tag,
            metadata: {
              archived: teamInitiative.archived,
              completedAt: teamInitiative.completed.value
                ? teamInitiative.completed
                : null,
            },
          };
        }) ?? []
      );
    })
    .with({ tenantInitiativesEnabled: true }, () => {
      return mitem.supportsInitiatives2?.map((i) => i.data) ?? [];
    })
    .otherwise(() => {
      return [];
    });

  const sortedInitiativeTags = [...supportedInitiatives].sort((a, b) => {
    return stringSort(a.tag.title, b.tag.title);
  });
  return sortedInitiativeTags;
};

interface MitemDetailsProps {
  mitem: MitemDetails_MitemFragment;
}

const CommitmentColumnHeader = ({
  numberOfSka,
  numberOfCommitted,
  onToggleAll,
}: {
  numberOfSka: number;
  numberOfCommitted: number;
  onToggleAll: (checked: boolean) => void;
}) => {
  const { t } = useTranslation();
  const allChecked = numberOfCommitted === numberOfSka;
  const someChecked = numberOfCommitted > 0;

  return (
    <span data-intercom-target="Toggle all committed">
      <Checkbox
        checked={allChecked}
        indeterminate={someChecked && !allChecked}
        onChange={(e) => {
          const checked = e.target.checked;
          onToggleAll(checked);
        }}
        className="mr"
      />
      {t('common.commitment_one')}
    </span>
  );
};

const MitemDetails = ({ mitem }: MitemDetailsProps) => {
  const { t } = useTranslation();
  return (
    <div className="flx">
      <div className="flx--1 SprintMitemTableCard__detailsSection">
        {mitem.supportedMigs.length > 0 &&
          mitem.supportedMigs.map((sm) => (
            <div className="flx flx--column mb" key={sm.id}>
              <Typography.Text>{t('SkaTable.AlignedToMig')}</Typography.Text>
              <Typography.Text type="secondary">{sm.name}</Typography.Text>
            </div>
          ))}
        {mitem.supportedMigs.length === 0 && (
          <div className="flx flx--column mb">
            <Typography.Text>{t('SkaTable.noMig')}</Typography.Text>
            <Typography.Text type="secondary">
              {mitem.noMigAssociation}
            </Typography.Text>
          </div>
        )}
        <div className="flx flx--column ">
          <Typography.Text>{t('common.definitionOfDone')}</Typography.Text>
          <Typography.Text type="secondary" style={{ whiteSpace: 'pre-wrap' }}>
            {mitem.definitionOfDone}
          </Typography.Text>
        </div>
      </div>
      <div className="flx--1 SprintMitemTableCard__detailsSection">
        <div>
          <Typography.Text>{t('common.history')}</Typography.Text>
          <div className="mt">
            <MitemHistory teamId={mitem.teamId} mitemId={mitem.id} />
          </div>
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SKA_TABLE__MILESTONE = gql`
  fragment SkaTable_Milestone on Milestone {
    id
    domainId {
      itemId
      tenantId
    }
    deadlineAt
    description
    name
    assignedTo {
      id
      domainId {
        itemId
      }
      email
      name
      displayName
    }
    metadata {
      completedAt
      status
      archived
      supports {
        ... on SupportedInitiative {
          item {
            id
            domainId {
              itemId
            }
            name
            tag {
              colorCode
              iconId
              title
            }
            metadata {
              completedAt
              archived
            }
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SKA_TABLE_MITEM_FRAGMENT = gql`
  fragment SkaTable_Mitem on Mitem {
    id
    status
    name
    deadline
    milestone
    tags {
      id
      name
      backgroundColor
    }
    owner {
      id
      name
      email
      displayName
      archivedAt
    }
    supportedInitiatives {
      id
      tag {
        title
        iconId
        colorCode
      }
      archived
      completed {
        value
        setAt
      }
    }
    supportsInitiatives2 {
      id
      data {
        id
        tag {
          title
          iconId
          colorCode
        }
        tag {
          title
          iconId
          colorCode
        }
        metadata {
          completedAt
          archived
        }
      }
    }
    supportedMigs {
      id
      domainId {
        teamId
        itemId
      }
    }
    ...MitemDetails_Mitem
  }
`;

//eslint-disable-next-line
const MITEM_DETAILS_FRAGMENT = gql`
  fragment MitemDetails_Mitem on Mitem {
    id
    definitionOfDone
    noMigAssociation
    teamId
    supportedMigs {
      id
      name
      domainId {
        teamId
        itemId
      }
    }
  }
`;
