import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Card, Col, Divider, Row, Typography } from 'antd';
import { CompanyInitiativeReportSection__TopInitiativeReportResponse2Fragment } from '../../../../../generated/graphql';
import { Icons } from '../../../initiatives_old/initiativesPageV1/InitiativeIcons';
import cx from 'classnames';

interface Props {
  companyInitiatives: CompanyInitiativeReportSection__TopInitiativeReportResponse2Fragment[];
}

export const InitiativeCardSection = ({ companyInitiatives }: Props) => {
  const { t } = useTranslation();

  // Break into rows of 3 initiatives per row
  const rows = [];
  for (let i = 0; i < companyInitiatives.length; i += 3) {
    rows.push(companyInitiatives.slice(i, i + 3));
  }

  return rows.map((row, index) => (
    <Row
      key={index}
      gutter={[16, 16]}
      justify="start"
      className={cx('vertically-center', {
        'page-break': index !== 0,
      })}
    >
      {row.map((initiative) => (
        <Col key={initiative.id} xs={8} sm={8} md={8}>
          <Card className="mb--l" style={{ fontSize: 10 }}>
            <h3 className="mb--l">
              {Icons[initiative.initiative.tag.iconId as keyof typeof Icons]}
              <span className="ml--s">{initiative.initiative.name}</span>
            </h3>
            <Typography.Text strong>
              {t('InitiativeCardSection.purpose')}
            </Typography.Text>
            <div>{initiative.initiative.description}</div>

            <Divider />
            <Typography.Text strong>
              {t('InitiativeCardSection.totalMilestones', {
                numberOfMilestones:
                  initiative.accumulatedStats.milestones.overdue +
                  initiative.accumulatedStats.milestones.completed +
                  initiative.accumulatedStats.milestones.upcoming,
              })}
            </Typography.Text>
            <div>
              {t('InitiativeCardSection.completed', {
                numberOfMilestones:
                  initiative.accumulatedStats.milestones.completed,
              })}
            </div>
            <div>
              {t('InitiativeCardSection.overdue', {
                numberOfMilestones:
                  initiative.accumulatedStats.milestones.overdue,
              })}
            </div>
            <div>
              {t('InitiativeCardSection.upcoming', {
                numberOfMilestones:
                  initiative.accumulatedStats.milestones.upcoming,
              })}
            </div>
          </Card>
        </Col>
      ))}
    </Row>
  ));
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_INITIATIVES_FOR_CARD_SECTION = gql`
  fragment CompanyInitiativeReportSection__TopInitiativeReportResponse2 on TopInitiativeReport2 {
    id
    domainId {
      itemId
      tenantId
    }
    companyInitiative
    accumulatedStats {
      milestones {
        completed
        overdue
        upcoming
      }
    }
    initiative {
      id
      name
      description
      domainId {
        itemId
        tenantId
      }
      tag {
        iconId
        colorCode
        title
      }
      profit {
        potential
        current
      }
      revenue {
        potential
        current
      }
      metadata {
        status
      }
    }
  }
`;
