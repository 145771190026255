import { useTranslation } from 'react-i18next';
import { MilestoneTable } from './components/milestoneTable/MilestoneTable';
import { CreateMilestoneButton } from './components/createMilestoneButton/CreateMilestoneButton';
import { gql, useSuspenseQuery } from '@apollo/client';
import {
  Action,
  GetMilestonesForInitiativeDocument,
  InitiativeProgressSection__InitiativeDetailedReportResponseFragment,
  ProgressSection__InitiativeLightFragment,
} from '../../../../../generated/graphql';
import { Col, Input, Row, Select } from 'antd';
import {
  MilestoneOrActiveToggle,
  MilestoneOrActiveToggleEnum,
} from '../milestoneOrActivityToggle/MilestoneOrActiveToggle';
import { Suspense, useState } from 'react';
import { ProgessStatusFilter } from './components/ProgessStatusFilter';
import { PermissionChecker } from '../../../../../PermissionChecker';
import { SprintKeyActivityTable } from './components/milestoneTable/SprintKeyActivityTable';
import { ProgressSectionContentSkeleton } from './ProgressSectionContentSkeleton';
import { InitiativeTag } from '../../../../../components/initiative/InitiativeTag';
import { CreateSkaForTenantInitiativeButton } from './components/createSprintKeyActivityForInitiative/CreateSkaForTenantInitiativeButton';
import { SearchOutlined } from '@ant-design/icons';
import { ProgressSectionSkeleton } from './ProgressSection.skeleton';

interface Props {
  report: InitiativeProgressSection__InitiativeDetailedReportResponseFragment;
  initiative: ProgressSection__InitiativeLightFragment;
  initiativeId: string;
}

export const ProgressSection = ({
  initiative,
  report,
  initiativeId,
}: Props) => {
  const { data: milestoneData } = useSuspenseQuery(
    GetMilestonesForInitiativeDocument,
    {
      variables: { initiativeId: initiativeId },
    }
  );
  const milestones = milestoneData?.milestonesForInitiative.milestones ?? [];
  const [filterByTitle, setFilterByTitle] = useState<null | string>(null);
  const [filterByInitiative, setFilterByInitiative] = useState<null | string>(
    null
  );

  const [statusFilters, setStatusFilters] = useState<{
    completed: boolean;
    overdue: boolean;
    upcoming: boolean;
  }>({ completed: true, overdue: true, upcoming: true });
  const { t } = useTranslation();
  const [milestonesOrActivities, setMilestonesOrActivities] =
    useState<MilestoneOrActiveToggleEnum>(
      MilestoneOrActiveToggleEnum.Milestones
    );

  const stats =
    milestonesOrActivities === MilestoneOrActiveToggleEnum.Milestones
      ? report.accumulatedStats.milestones
      : report.accumulatedStats.activities;

  const subInitiativesOptions = report.allSubInitiatives.map((i) => ({
    value: i.initiative.id,
    label: (
      <InitiativeTag
        tag={i.initiative.tag}
        completed={i.initiative.metadata.completedAt != null}
        archived={i.initiative.metadata.archived}
      ></InitiativeTag>
    ),
    searchText: i.initiative.tag.title,
  }));

  const filterOptions = [
    {
      value: initiative.id,
      label: (
        <InitiativeTag
          tag={initiative.tag}
          completed={initiative.metadata.completedAt != null}
          archived={initiative.metadata.archived}
        ></InitiativeTag>
      ),
      searchText: initiative.tag.title,
    },
    ...subInitiativesOptions,
  ];

  return (
    <div>
      <div className="mb--xl mt flx">
        <h3>{t('ProgressSection.title')}</h3>
        <div className="ml--auto mr">
          <PermissionChecker
            resourceOwner={{
              type: 'TenantResource',
              requestedAction: {
                resource: 'milestone',
                action: Action.CREATE,
              },
            }}
          >
            <CreateMilestoneButton initiative={initiative} />
          </PermissionChecker>
        </div>
        <div>
          <CreateSkaForTenantInitiativeButton
            initiativeId={initiativeId}
            contributingTeams={report.teamStats.map((t) => ({
              id: t.team.domainId.itemId,
              name: t.team.name,
            }))}
          />
        </div>
      </div>
      <div className="mb--xl mt flx flx--jc-space-between flx--ai-center">
        <Labeled text={t('ProgressSection.resolution')}>
          <MilestoneOrActiveToggle
            value={milestonesOrActivities}
            onChange={setMilestonesOrActivities}
            totalMilestones={
              report.accumulatedStats.milestones.completed +
              report.accumulatedStats.milestones.overdue +
              report.accumulatedStats.milestones.upcoming
            }
            totalActivities={
              report.accumulatedStats.activities.completed +
              report.accumulatedStats.activities.overdue +
              report.accumulatedStats.activities.upcoming
            }
          />
        </Labeled>

        <div className="flx">
          <Labeled text={t('ProgressSection.initiativeAlignment')}>
            <Select
              options={filterOptions}
              placeholder="Filter by initiative"
              allowClear
              className="mr--xl"
              onChange={(value) => setFilterByInitiative(value)}
              value={filterByInitiative}
              style={{ width: 250 }}
            />
          </Labeled>
          <Labeled text={t('ProgressSection.search')}>
            <Input
              prefix={<SearchOutlined className="txt--secondary" />}
              placeholder={t('ProgressSection.searchPlaceholder')}
              allowClear
              onClear={() => setFilterByTitle(null)}
              onChange={({ target: { value } }) => setFilterByTitle(value)}
              style={{ width: 250 }}
            />
          </Labeled>
        </div>
      </div>
      <Suspense
        fallback={
          <ProgressSectionContentSkeleton
            milestonesOrActivities={milestonesOrActivities}
          />
        }
      >
        <Row gutter={[30, 30]} className="mb--xl">
          <Col xs={12} xl={8}>
            <ProgessStatusFilter
              stats={stats}
              status="completed"
              checked={statusFilters.completed}
              onChange={() =>
                setStatusFilters({
                  ...statusFilters,
                  completed: !statusFilters.completed,
                })
              }
            />
          </Col>
          <Col xs={12} xl={8}>
            <ProgessStatusFilter
              stats={stats}
              status="overdue"
              checked={statusFilters.overdue}
              onChange={() =>
                setStatusFilters({
                  ...statusFilters,
                  overdue: !statusFilters.overdue,
                })
              }
            />
          </Col>
          <Col xs={12} xl={8}>
            <ProgessStatusFilter
              stats={stats}
              status="upcoming"
              checked={statusFilters.upcoming}
              onChange={() =>
                setStatusFilters({
                  ...statusFilters,
                  upcoming: !statusFilters.upcoming,
                })
              }
            />
          </Col>
        </Row>
        {milestonesOrActivities === MilestoneOrActiveToggleEnum.Milestones && (
          <MilestoneTable
            report={report}
            initiativeId={initiativeId}
            milestones={milestones}
            filters={{
              status: statusFilters,
              title: filterByTitle,
              initiativeId: filterByInitiative,
            }}
          />
        )}
        {milestonesOrActivities === MilestoneOrActiveToggleEnum.Activities && (
          <SprintKeyActivityTable
            initiative={initiative}
            filters={{
              status: {
                completed: statusFilters.completed,
                overdue: statusFilters.overdue,
                planned: statusFilters.upcoming,
              },
              title: filterByTitle,
              initiativeId: filterByInitiative,
            }}
          />
        )}
      </Suspense>
    </div>
  );
};

ProgressSection.Skeleton = ProgressSectionSkeleton;

const Labeled = ({
  text,
  children,
}: {
  text: string;
  children: React.ReactNode;
}) => {
  return (
    <div>
      <div className="txt--secondary font-size--sm">{text}</div>
      {children}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_MILESTONES_FOR_INITIATIVE = gql`
  query getMilestonesForInitiative($tenantId: ID, $initiativeId: ID!) {
    milestonesForInitiative(tenantId: $tenantId, initiativeId: $initiativeId) {
      milestones {
        id
        name
        description
        deadlineAt
        assignedTo {
          id
          domainId {
            itemId
          }
          data {
            id
            name
            email
            displayName
            initials
          }
        }
        metadata {
          status
        }
        ...ProgressSection__Milestone
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PROGRESS_SECTION__MILESTONES2 = gql`
  fragment ProgressSection__Milestone on MilestoneWithLinks {
    id
    name
    description
    deadlineAt
    assignedTo {
      id
      domainId {
        itemId
      }
      data {
        id
        name
        email
        displayName
        initials
      }
    }
    metadata {
      status
    }
    ...MilestoneTable__Milestone
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PROGRESS_SECTION__INITIATIVE_LIGHT = gql`
  fragment ProgressSection__InitiativeLight on InitiativeLight {
    ...CreateMilestoneButton__InitiativeLight
    ...SprintKeyActivityTable__InitiativeLight
    domainId {
      itemId
      tenantId
    }
    id
    name
    tag {
      iconId
      title
      colorCode
    }
    metadata {
      completedAt
      archived
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_PROGRESS_SECTION__INITIATIVE_DETAILED_REPORT_RESPONSE = gql`
  fragment InitiativeProgressSection__InitiativeDetailedReportResponse on InitiativeDetailedReportResponse {
    id
    domainId {
      itemId
      tenantId
    }
    allSubInitiatives {
      id
      initiative {
        id
        domainId {
          itemId
        }
        name
        tag {
          iconId
          title
          colorCode
        }
        metadata {
          completedAt
          archived
        }
      }
      domainId {
        itemId
      }
    }
    accumulatedStats {
      milestones {
        completed
        overdue
        upcoming
      }
      activities {
        completed
        overdue
        upcoming
      }
    }
    ...MilestoneTable__InitiativeDetailedReportResponse
    teamStats {
      team {
        id
        name
        domainId {
          itemId
        }
      }
    }
  }
`;
