import React, { Component } from 'react';
import { Layer } from 'recharts';

export default class Link extends Component<any, any> {
  static displayName = 'SankeyLinkDemo';

  // Determine color based on the target node
  getLinkColor = () => {
    const { target, nodes } = this.props;

    // Add defensive checks to prevent undefined errors
    if (!nodes || typeof target === 'undefined') {
      return 'url(#linkGradient)'; // Default fallback gradient
    }

    const targetNodeName = nodes[target]?.name;

    if (targetNodeName === 'Profit') {
      return 'rgba(0, 197, 159, 0.5)'; // Green for Profit
    } else if (targetNodeName === 'Revenue') {
      return 'rgba(0, 136, 254, 0.5)'; // Blue for Revenue
    }
    return 'url(#linkGradient)'; // Default gradient for other cases
  };

  render() {
    const {
      sourceX,
      targetX,
      sourceY,
      targetY,
      sourceControlX,
      targetControlX,
      linkWidth,
      index,
    } = this.props;

    const fill = this.getLinkColor(); // Dynamically get the color

    return (
      <Layer key={`CustomLink${index}`}>
        <path
          d={`
            M${sourceX},${sourceY + linkWidth / 2}
            C${sourceControlX},${sourceY + linkWidth / 2}
              ${targetControlX},${targetY + linkWidth / 2}
              ${targetX},${targetY + linkWidth / 2}
            L${targetX},${targetY - linkWidth / 2}
            C${targetControlX},${targetY - linkWidth / 2}
              ${sourceControlX},${sourceY - linkWidth / 2}
              ${sourceX},${sourceY - linkWidth / 2}
            Z
          `}
          fill={fill}
          strokeWidth="0"
          onMouseEnter={() => {
            this.setState({ fill: 'rgba(255, 192, 0, 0.7)' }); // Highlight on hover
          }}
          onMouseLeave={() => {
            this.setState({ fill: '' }); // Reset color on leave
          }}
        />
      </Layer>
    );
  }
}
