import { useState } from 'react';
import { SkaInitialValues } from '../../planning/components/SprintKaForm';
import { PureQueryOptions } from '@apollo/client';

interface ModalState {
  modalOpen: boolean;
  prefilledValues?: SkaInitialValues;
  refetchQueries?: Array<string | PureQueryOptions>;
}

export const useCreateMitemModal = () => {
  const [modalState, setModalState] = useState<ModalState>({
    modalOpen: false,
  });

  const openCreateForm = ({
    deadline,
    preselectedMilestone,
    refetchQueries,
  }: {
    deadline?: string;
    preselectedMilestone?: {
      id: string;
      domainId: { itemId: string; tenantId: string };
    };
    refetchQueries?: Array<string | PureQueryOptions>;
  } = {}) => {
    setModalState({
      modalOpen: true,
      prefilledValues: {
        deadline,
        supportsMilestones: preselectedMilestone && [preselectedMilestone],
      },
      refetchQueries: refetchQueries && refetchQueries,
    });
  };

  const closeAndClearDeadline = () => {
    setModalState({
      modalOpen: false,
      prefilledValues: {
        deadline: undefined,
        supportsMilestones: undefined,
      },
      refetchQueries: undefined,
    });
  };

  return {
    isOpen: modalState.modalOpen,
    prefilledValues: modalState.prefilledValues,
    refetchQueries: modalState.refetchQueries,
    openModal: openCreateForm,
    closeModal: closeAndClearDeadline,
  };
};

export type CreateMitemModal = ReturnType<typeof useCreateMitemModal>;
