import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Btn } from '../../../components/Button';
import { FullscreenModal } from '../../../components/FullscreenModal';
import { CompanyInitiativeReportSection } from './CompanyInitiativeReportSection/CompanyInitiativeReportSection';
import { CompanyMigReportSection } from './CompanyMigReportSection/CompanyMigReportSection';
import { exportReportToPdf } from './exportReportToPdf';
import './ReportGenerator.page.less';

export const ReportGeneratorPage = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isPreviewMode, setPreviewMode] = useState(false);

  return (
    <>
      <div className="center-content mt">
        <Btn onClick={() => setIsOpen(true)}>
          {t('ReportGeneratorPage.createReportButton')}
        </Btn>
      </div>
      <FullscreenModal
        open={isOpen}
        keyboard={false}
        onCancel={() => setIsOpen(false)}
        onClose={() => setIsOpen(false)}
        destroyOnClose
        footer={
          <div className="flx">
            <div className="mr--auto ml--auto">
              <Btn className="mr" onClick={() => setIsOpen(false)}>
                {t('ReportGeneratorPage.closeButton')}
              </Btn>
              <Btn
                className="mr"
                onClick={() => setPreviewMode(!isPreviewMode)}
              >
                {isPreviewMode
                  ? t('ReportGeneratorPage.editButton')
                  : t('ReportGeneratorPage.previewButton')}
              </Btn>
              <Btn
                type="primary"
                onClick={() => {
                  setPreviewMode(true);
                  setTimeout(() => {
                    exportReportToPdf();
                  }, 200);
                }}
                className="export-btn"
              >
                {t('ReportGeneratorPage.exportPdfButton')}
              </Btn>
            </div>
          </div>
        }
      >
        <>
          <div id="report-container" className="content mt">
            <CompanyMigReportSection isPreviewMode={isPreviewMode} />
            <CompanyInitiativeReportSection />
          </div>
        </>
      </FullscreenModal>
    </>
  );
};
