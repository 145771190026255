import dayjs from 'dayjs';
import { MitemStatus } from '../../../generated/graphql';

export interface WeeklyKA {
  weeklyKeyActivityId: string;
  name: string;
  achievementDescriptionMandatory: boolean;
  definitionOfDoneHtml: string | null;
  periodEndDate: string | null;
  unit: string;
  progress: {
    commitment: number | null;
    note: string | null;
    achievement: number | null;
  };
}

export interface SprintKA {
  sprintKeyActivityId: string;
  name: string;
  deadline: string;
  ownerId: string;
  periodEndDate: string | null;
  definitionOfDone: string;
  mitemStatus: MitemStatus;
  progress: {
    note: string | null;
    committed: boolean;
    completedAt: string | null;
    permittedToComplete: boolean;
  };
}

export const mergeKeyActivities = (
  weeklyKAs: WeeklyKA[],
  sprintKAs: SprintKA[]
) =>
  [...weeklyKAs, ...sprintKAs].sort((a, b) => {
    const dateA = isSprintKA(a) ? dayjs(a.deadline) : dayjs(a.periodEndDate);
    const dateB = isSprintKA(b) ? dayjs(b.deadline) : dayjs(b.periodEndDate);

    // Handle invalid dates: if invalid, move them to the end
    if (!dateA.isValid()) return 1;
    if (!dateB.isValid()) return -1;

    return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
  });

export const getCommittedActivities = (teamProgress: {
  periodEndTime: string;
  periodStartTime: string;
  accelerationMeetingId: string | null;
  weeklyKeyActivityProgress: WeeklyKA[];
  sprintKeyActivityProgress: SprintKA[];
}) => {
  const weekliesCommitments = teamProgress.weeklyKeyActivityProgress.filter(
    (ka) => ka.progress.commitment && ka.progress.commitment > 0
  );

  const sprintCommitments = teamProgress.sprintKeyActivityProgress.filter(
    (ka) => {
      const notYetCompleted = ka.progress.completedAt == null;
      const completedInCurrentPeriod =
        ka.progress.completedAt &&
        ka.progress.completedAt >= teamProgress.periodStartTime;
      const hasDeadlineBeforeOrInCurrentPeriod =
        ka.deadline <= teamProgress.periodEndTime;
      const hasCommitment = ka.progress.committed;
      const notYetCompletedOrCompletedInCurrentPeriod =
        notYetCompleted || completedInCurrentPeriod;

      return (
        hasCommitment ||
        (hasDeadlineBeforeOrInCurrentPeriod &&
          notYetCompletedOrCompletedInCurrentPeriod)
      );
    }
  );

  const list = [...weekliesCommitments, ...sprintCommitments];
  return list;
};

export const isSprintKA = (ka: SprintKA | WeeklyKA): ka is SprintKA =>
  (ka as SprintKA).sprintKeyActivityId !== undefined;
