import { useTranslation } from 'react-i18next'; // Adjust this import according to your i18n setup
import { CheckIcon } from '../../../../../../icons/overviewIcons/CheckIcon';
import { WarningIcon } from '../../../../../../icons/performanceIcons/WarningIcon';
import { Switch } from 'antd';
import { UpcomingEventIcon } from '../../../../../../icons/overviewIcons/UpcomingEventIcon';
import { Colors } from '../../../../../componentLibrary/Colors';
import './ProgessStatusFilter.less';

interface Props {
  status: 'completed' | 'overdue' | 'upcoming';
}

export const ProgessStatusFilterSkeleton = ({ status }: Props) => {
  const { t } = useTranslation();

  const getStatusConfig = (status: 'completed' | 'overdue' | 'upcoming') => {
    switch (status) {
      case 'completed':
        return {
          backgroundColor: Colors.Lights.OK,
          color: Colors.Status.OK_GREEN,
          icon: <CheckIcon style={{ fontSize: 20 }} />,
          label: t('common.completed'),
        };
      case 'overdue':
        return {
          backgroundColor: Colors.Lights.WARNING,
          color: Colors.Status.OVERDUE,
          icon: <WarningIcon style={{ fontSize: 20 }} />,
          label: t('common.overdue'),
        };
      case 'upcoming':
        return {
          backgroundColor: Colors.Lights.FUTURE,
          color: Colors.Status.FUTURE_PURPLE,
          icon: <UpcomingEventIcon style={{ fontSize: 20 }} />,
          label: t('common.upcoming'),
        };
      default:
        return {
          backgroundColor: '',
          color: '',
          icon: null,
          label: '',
        };
    }
  };

  const { backgroundColor, color, icon, label } = getStatusConfig(status);

  return (
    <div className="ProgressStatusFilter__statusCards flx--ai-center flx--jc-space-between clickable">
      <div className="flx">
        <div
          style={{ backgroundColor, color }}
          className="ProgressStatusFilter__iconFrame mr--l"
        >
          <div className="center-content">{icon}</div>
        </div>
        <div>
          <div className="txt--secondary font-size--sm">Show</div>
          <div className="card__header flx flx--ai-center">
            <h4 className="space--r">{label}</h4>(-)
          </div>
        </div>
      </div>
      <Switch disabled checked={true} />
    </div>
  );
};
