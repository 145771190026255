import React from 'react';
import { Rectangle, Layer } from 'recharts';

export default function Node({
  x,
  y,
  width,
  height,
  index,
  payload,
  containerWidth,
}: any) {
  const isOut = x + width + 6 > containerWidth;

  if (payload.name !== 'Revenue' && payload.name !== 'Profit') {
    return (
      <Layer key={`CustomNode${index}`}>
        <Rectangle
          x={x}
          y={y}
          width={width}
          height={height}
          fill={payload.color}
          fillOpacity="1"
        />
        <text
          textAnchor={isOut ? 'end' : 'start'}
          x={isOut ? x - 6 : x + width + 6}
          y={y + height / 2}
          fontSize="8"
          stroke="#333"
          strokeOpacity="0.2"
        >
          {payload.name}
        </text>
        <text
          textAnchor={isOut ? 'end' : 'start'}
          x={isOut ? x - 6 : x + width + 6}
          y={y + height / 2 + 12}
          fontSize="6"
          stroke="#333"
          strokeOpacity="0.2"
        >
          Profit:
          {payload.profit}
        </text>
        <text
          textAnchor={isOut ? 'end' : 'start'}
          x={isOut ? x - 6 : x + width + 6}
          y={y + height / 2 + 24}
          fontSize="6"
          stroke="#333"
          strokeOpacity="0.2"
        >
          Revenue:
          {payload.revenue}
        </text>
      </Layer>
    );
  }

  return (
    <Layer key={`CustomNode${index}`}>
      <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill="#5192ca"
        fillOpacity="1"
      />
      <text
        textAnchor={isOut ? 'end' : 'start'}
        x={isOut ? x - 6 : x + width + 6}
        y={y + height / 2}
        fontSize="10"
        stroke="#333"
        strokeOpacity="0.2"
      >
        {payload.name}
      </text>

      <text
        textAnchor={isOut ? 'end' : 'start'}
        x={isOut ? x - 6 : x + width + 6}
        y={y + height / 2 + 13}
        fontSize="8"
        stroke="#333"
        strokeOpacity="0.5"
      >
        {payload.value}
      </text>
    </Layer>
  );
}
