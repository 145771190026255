import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

export const InitiativesNavigationSkeleton = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="mb">
        <div className="flx mb--l mr--l">
          <Select style={{ width: '100%' }} disabled loading />
        </div>
        <h3 className="mb--xs">
          {t('InitiativesNavigation.companyInitiatives')}
        </h3>
        <div className="flx flx--ai-center pl--xl mb">
          <Skeleton width={28} height={20} className="mr--s" />
          <Skeleton width={90} />
        </div>
        <div className="flx flx--ai-center pl--xl">
          <Skeleton width={28} height={20} className="mr--s" />
          <Skeleton width={100} />
        </div>
      </div>
    </div>
  );
};
