import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next'; // Added import
import { CompanyMigReport_MigV2Fragment } from '../../../../generated/graphql';
import MigReportGraph from './MigReportGraph';
import { useState } from 'react';
import { Alert } from 'antd';
import { CommentOutlined } from '@ant-design/icons';
import { Btn } from '../../../../components/Button';
import './CompanyMigReport.less';
import TextArea from 'antd/es/input/TextArea';

interface Props {
  mig: CompanyMigReport_MigV2Fragment;
  isPreviewMode: boolean;
}

export const CompanyMigReport = ({ mig, isPreviewMode }: Props) => {
  const { t } = useTranslation(); // Added translation hook
  const [excludeSection, setExcludeSection] = useState(false);
  const [freeText, setFreeText] = useState<string | undefined>();
  return (
    <>
      <div
        style={{ fontSize: 10 }}
        className={
          excludeSection
            ? 'CompanyMigReport--excludeSection exclude-from-pdf unsplittable'
            : ''
        }
      >
        <h3 className="mb--xl">{mig.name}</h3>
        <div className="flx mb--l">
          <div className="flx--6 mr">
            <div className="mb--l">
              <div className="mb bold">
                {t('CompanyMigReport.descriptionTitle')}
              </div>
              {mig.description ? (
                mig.description
              ) : (
                <span className="txt--disabled">
                  {t('CompanyMigReport.noDescription')}
                </span>
              )}
            </div>
            <div className="mb--l">
              <div className="mb bold">
                {t('CompanyMigReport.summaryTitle')}
              </div>
              -
            </div>
            <div>
              <div className="mb bold">
                {t('CompanyMigReport.supportedByTitle')}
              </div>
            </div>
          </div>
          <div className="flx--4">
            <div>
              <div className="mb bold">
                {t('CompanyMigReport.progressOverTimeTitle')}
              </div>
              <MigReportGraph mig={mig} />
              <div
                style={{
                  display: 'flex',
                  marginBottom: '10px',
                }}
              >
                <div>
                  <div>
                    {t('CompanyMigReport.migOutcomeForDate', {
                      date: 'Mar 31',
                    })}
                  </div>
                  <div>
                    {t('CompanyMigReport.migGoalForDate', { date: 'Mar 31' })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb--t">
          <Alert
            className="preserveLinebreaks"
            message={
              <div className="flx">
                <CommentOutlined className="mr" />
                {isPreviewMode ? (
                  <>{freeText}</>
                ) : (
                  <TextArea
                    value={freeText}
                    rows={3}
                    onChange={(v) => setFreeText(v.target.value)}
                  />
                )}
              </div>
            }
            closable
            onClose={() => setFreeText(undefined)}
            type="info"
          />
        </div>
      </div>
      {!isPreviewMode && (
        <div className="flx exclude-from-pdf">
          <Btn
            className="ml--auto mr--auto mt "
            type="link"
            onClick={() => setExcludeSection(!excludeSection)}
          >
            {excludeSection
              ? t('CompanyMigReport.includeSectionButton')
              : t('CompanyMigReport.excludeSectionButton')}
          </Btn>
        </div>
      )}
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const COMPANY_MIG_REPORT__QUERY = gql`
  fragment CompanyMigReport_MigV2 on MigV2 {
    id
    name
    status
    timePeriodInterval
    description
    domainId {
      itemId
      teamId
      tenantId
    }
    team {
      id
      name
      teamHierarchy {
        id
        name
        tenantId
      }
    }
    periodData {
      targetDate
      actual
      goal
      historic
    }
    supportedByMigs {
      id
      name
      domainId {
        itemId
        teamId
        tenantId
      }
      team {
        id
        name
        teamHierarchy {
          id
          name
          tenantId
        }
      }
    }
    ...MigGraph_MigV2
  }
`;
