import { Modal, Table, Tooltip } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { ExpandArrow } from '../../../../../../../components/ExpandArrow';
import { gql } from '@apollo/client';
import {
  MilestoneStatus,
  MilestoneTable__InitiativeDetailedReportResponseFragment,
  MilestoneTable__MilestoneFragment,
  MitemStatus,
} from '../../../../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { WarningIcon } from '../../../../../../../icons/performanceIcons/WarningIcon';
import { Colors } from '../../../../../../componentLibrary/Colors';
import { CheckIcon } from '../../../../../../../icons/overviewIcons/CheckIcon';
import { UpcomingEventIcon } from '../../../../../../../icons/overviewIcons/UpcomingEventIcon';
import { MilestoneActivitiesTable } from './components/MilestoneActivitiesTable';
import { friendlyDate } from '../../../../../../../services/dateFormats';
import { useMilestoneModal } from './hooks/useMilestoneModal';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { Btn } from '../../../../../../../components/Button';
import { MilestoneDetails } from './components/milestoneActions/components/MilestoneDetails';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from '../../../../../../../components/ErrorPage';
import { StatusTag } from '../../../../../../../components/StatusTag';
import { ManageMilestoneTeams } from './components/milestoneActions/components/ManageMilestoneTeams';
import { stringSort } from '../../../../../../../services/stringSort';
import { EditMilestoneModal } from './components/milestoneActions/components/EditMilestoneModal';
import { CreateSkaForTenantInitiativeDrawer } from '../createSprintKeyActivityForInitiative/CreateSkaForTenantInitiativeDrawer';
import { MarkMilestoneAsCompletedModal } from './components/markMilestoneAsCompletedModal/MarkMilestoneAsCompletedModal';
import { ReactivateMilestoneModal } from './components/reactivateMilestoneModal/ReactivateMilestoneModal';
import { ArchiveMilestoneModal } from './components/archiveMilestoneAModal/ArchiveMilestoneModal';
import { RealignMilestoneModal } from './components/realignMilestoneModal/RealignMilestoneModal';
import { EditOutlined } from '@ant-design/icons';
import { SearchOutlineIcon } from '../../../../../../../icons/initiativeIcons/SearchOutlineIcon';
import { MilestoneActions } from './components/milestoneActions/MilestoneActions';

interface Props {
  report: MilestoneTable__InitiativeDetailedReportResponseFragment;
  milestones: MilestoneTable__MilestoneFragment[];
  initiativeId: string;
  filters: {
    status: {
      completed: boolean;
      overdue: boolean;
      upcoming: boolean;
    };
    title: string | null;
    initiativeId: string | null;
  };
}

const matchMilestoneByStatus = (
  status: MilestoneStatus,
  filterStatus: {
    completed: boolean;
    overdue: boolean;
    upcoming: boolean;
  }
) => {
  return filterStatus[status.toLowerCase() as keyof typeof filterStatus];
};

const matchMilestoneByName = (name: string, filterTitle: string | null) => {
  if (filterTitle == null || filterTitle === '') return true;
  return name.toLowerCase().includes(filterTitle.toLowerCase());
};

const matchMilestoneBySupportsInitiatives = (
  milestoneWithStats: MilestoneWithStats,
  filterInitiativeId: string | null
) => {
  if (filterInitiativeId == null || filterInitiativeId === '') return true;
  if (
    milestoneWithStats.milestone.metadata.supportsInitiatives.some(
      (si) => si.id === filterInitiativeId
    )
  ) {
    return true;
  }
  return false;
};

type MilestoneWithStats = {
  milestone: MilestoneTable__MilestoneFragment;
  stats: {
    completed: number | '-';
    overdue: number | '-';
    upcoming: number | '-';
  };
};

export const MilestoneTable = ({
  report,
  filters,
  initiativeId,
  milestones,
}: Props) => {
  const { t } = useTranslation();
  const modalHandler = useMilestoneModal();
  const [milestonesWithStatsMap, setMilestonesWithStatsMap] = useState<
    Map<string, MilestoneWithStats>
  >(new Map());

  const milestoneReports = useMemo(() => {
    return [
      ...report.milestones,
      ...report.allSubInitiatives.flatMap((i) => i.milestones),
    ];
  }, [report]);

  useEffect(() => {
    const computedMilestonesWithStatsMap = new Map();

    milestones.forEach((m) => {
      const milestoneReport = milestoneReports.find((mr) => mr.id === m.id);
      computedMilestonesWithStatsMap.set(m.domainId.itemId, {
        milestone: m,
        stats: {
          completed: milestoneReport?.stats.completed ?? '-',
          overdue: milestoneReport?.stats.overdue ?? '-',
          upcoming: milestoneReport?.stats.upcoming ?? '-',
        },
      });
    });

    setMilestonesWithStatsMap(computedMilestonesWithStatsMap);
  }, [milestones, milestoneReports]);

  const filteredMilestoneReports = Array.from(
    milestonesWithStatsMap.values()
  ).filter((m) => {
    if (!matchMilestoneByStatus(m.milestone.metadata.status, filters.status))
      return false;
    if (!matchMilestoneByName(m.milestone.name, filters.title)) return false;
    if (!matchMilestoneBySupportsInitiatives(m, filters.initiativeId))
      return false;
    return true;
  });

  const milestoneColumns: ColumnProps<MilestoneWithStats>[] = [
    {
      title: t('common.deadline'),
      width: 120,
      key: 'deadlineAt',
      defaultSortOrder: 'ascend',
      sorter: (a, b) =>
        stringSort(a.milestone.deadlineAt, b.milestone.deadlineAt),
      dataIndex: ['milestone', 'deadlineAt'],
      render: (deadline) => {
        return <div className="text-c">{friendlyDate(deadline)}</div>;
      },
    },
    {
      title: t('common.status'),
      key: 'status',
      width: 130,
      dataIndex: ['milestone', 'name'],
      render: (text, milestoneWithStats) => {
        return (
          <StatusTag
            status={milestoneWithStats.milestone.metadata.status}
            showIcon
          />
        );
      },
    },
    {
      title: t('MilestoneTable.milestoneColumn'),
      key: 'name',
      dataIndex: ['milestone', 'name'],
      render: (text, milestoneInfoWithStats) => {
        return (
          <div>
            <div>{text}</div>
            <div className="txt--secondary h5">
              {milestoneInfoWithStats.milestone.metadata.supportsInitiatives
                .map((s) => s.data.tag.title)
                .join(', ')}
            </div>
          </div>
        );
      },
    },
    {
      title: (
        <Tooltip placement="topRight" title={t('MilestoneTable.checkIcon')}>
          <CheckIcon style={{ fontSize: 15, color: Colors.Status.OK_GREEN }} />
        </Tooltip>
      ),
      key: 'completed',
      width: 90,
      dataIndex: ['stats', 'completed'],
    },
    {
      title: (
        <Tooltip placement="topRight" title={t('MilestoneTable.warningIcon')}>
          <WarningIcon
            style={{ fontSize: 15, color: Colors.Status.OVERDUE }}
            className="space--r"
          />
        </Tooltip>
      ),
      key: 'overdue',
      dataIndex: ['stats', 'overdue'],
      width: 90,
    },
    {
      title: (
        <Tooltip
          placement="topRight"
          title={t('MilestoneTable.upcomingEventIcon')}
        >
          <UpcomingEventIcon
            style={{ fontSize: 15, color: Colors.Status.FUTURE_PURPLE }}
            className="space--r"
          />
        </Tooltip>
      ),
      key: 'upcoming',
      width: 90,
      dataIndex: ['stats', 'upcoming'],
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      render: (_, milestoneWithStats) => {
        return (
          <div className="flx">
            <Btn
              type="text"
              icon={<SearchOutlineIcon />}
              className="txt--action-blue font-size--lg"
              onClick={(e) => {
                e.stopPropagation();
                modalHandler.openModal({
                  milestoneId: milestoneWithStats.milestone.domainId.itemId,
                  type: 'details',
                });
              }}
            />
            <MilestoneActions
              openModal={modalHandler.openModal}
              milestone={milestoneWithStats.milestone}
              initiativeId={initiativeId}
            />
          </div>
        );
      },
    },
    Table.EXPAND_COLUMN,
  ];

  const sharedModalProps = {
    footer: null,
    destroyOnClose: true,
    onCancel: modalHandler.closeModal,
  };

  const activeMilestoneId = modalHandler.activeModal?.milestoneId;

  return (
    <div>
      <Modal
        {...sharedModalProps}
        open={modalHandler.activeModal?.type === 'manageTeams'}
      >
        {modalHandler.activeModal?.type === 'manageTeams' &&
          activeMilestoneId && (
            <Suspense
              fallback={
                <ManageMilestoneTeams.Skeleton
                  milestoneName={modalHandler.activeModal.milestoneName}
                />
              }
            >
              <ManageMilestoneTeams
                milestoneName={modalHandler.activeModal.milestoneName}
                milestoneId={activeMilestoneId}
              />
            </Suspense>
          )}
      </Modal>

      <CreateSkaForTenantInitiativeDrawer
        open={modalHandler.activeModal?.type === 'addKeyActivity'}
        onClose={modalHandler.closeModal}
        onSuccess={(ska) => {
          setMilestonesWithStatsMap((prevState) => {
            const updatedMap = new Map(prevState); // Create a shallow copy of the map

            ska.supportsMilestones.forEach((m) => {
              const milestoneEntry = updatedMap.get(m.domainId.itemId);

              if (milestoneEntry) {
                // Update the stats for the milestone
                const updatedStats = { ...milestoneEntry.stats };

                const updateStat = (statKey: keyof typeof updatedStats) => {
                  const currentStat = updatedStats[statKey];
                  if (typeof currentStat === 'number') {
                    updatedStats[statKey] = currentStat + 1;
                  } else {
                    updatedStats[statKey] = 1; // Convert from '-' to 1
                  }
                };

                if (
                  ska.status === MitemStatus.PLANNED ||
                  ska.status === MitemStatus.ACTIVE
                ) {
                  updateStat('upcoming');
                }

                if (ska.status === MitemStatus.COMPLETED) {
                  updateStat('completed');
                }

                if (ska.status === MitemStatus.OVERDUE) {
                  updateStat('overdue');
                }

                // Replace the updated milestone in the map
                updatedMap.set(m.domainId.itemId, {
                  ...milestoneEntry,
                  stats: updatedStats,
                });
              }
            });

            return updatedMap; // Return the updated map to set the new state
          });
        }}
        contributingTeams={
          modalHandler.activeModal?.type === 'addKeyActivity'
            ? modalHandler.activeModal.allowedTeams
            : []
        }
        milestone={
          modalHandler.activeModal?.type === 'addKeyActivity'
            ? {
                id: modalHandler.activeModal.milestoneId,
                domainId: modalHandler.activeModal.milestoneDomainId,
              }
            : undefined
        }
        initiativeId={initiativeId}
      />

      <EditMilestoneModal
        open={modalHandler.activeModal?.type === 'edit'}
        onClose={modalHandler.closeModal}
        milestoneId={activeMilestoneId}
      />

      <MarkMilestoneAsCompletedModal
        milestoneId={modalHandler.activeModal?.milestoneId}
        visible={modalHandler.activeModal?.type === 'markAsCompleted'}
        onClose={modalHandler.closeModal}
      />

      <ReactivateMilestoneModal
        milestoneId={modalHandler.activeModal?.milestoneId}
        visible={modalHandler.activeModal?.type === 'reactivate'}
        onClose={modalHandler.closeModal}
      />

      <RealignMilestoneModal
        milestoneId={modalHandler.activeModal?.milestoneId}
        visible={modalHandler.activeModal?.type === 'realign'}
        onClose={modalHandler.closeModal}
      />

      <ArchiveMilestoneModal
        milestoneId={modalHandler.activeModal?.milestoneId}
        visible={modalHandler.activeModal?.type === 'archive'}
        onClose={modalHandler.closeModal}
      />

      <Modal
        {...sharedModalProps}
        open={modalHandler.activeModal?.type === 'details'}
        title={t('MilestoneTable.milestoneDetails')}
        footer={
          activeMilestoneId && (
            <Btn
              type="link"
              icon={<EditOutlined />}
              onClick={() => {
                modalHandler.openModal({
                  type: 'edit',
                  milestoneId: activeMilestoneId,
                });
              }}
            >
              {t('common.edit')}
            </Btn>
          )
        }
      >
        <Suspense fallback={<MilestoneDetails.Skeleton />}>
          {modalHandler.activeModal?.milestoneId && (
            <MilestoneDetails
              milestoneId={modalHandler.activeModal?.milestoneId}
            />
          )}
        </Suspense>
      </Modal>
      <Table
        columns={milestoneColumns}
        dataSource={filteredMilestoneReports}
        style={{ borderRadius: '6px' }}
        expandable={{
          expandRowByClick: true,
          expandedRowRender: (milestoneReport) => (
            <ErrorBoundary FallbackComponent={ErrorPage}>
              <Suspense
                fallback={
                  <MilestoneActivitiesTable.Skeleton numberOfActivities={3} />
                }
              >
                <MilestoneActivitiesTable
                  milestoneItemId={milestoneReport.milestone.domainId.itemId}
                />
              </Suspense>
            </ErrorBoundary>
          ),
          expandIcon: ({ expanded, onExpand, record }) => (
            <ExpandArrow
              onClick={(e) => onExpand(record, e)}
              expanded={expanded}
            />
          ),
        }}
        pagination={false}
        rowKey={(milestoneReport) => milestoneReport.milestone.id}
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_TABLE__MILESTONES = gql`
  fragment MilestoneTable__Milestone on MilestoneWithLinks {
    id
    domainId {
      itemId
      tenantId
    }
    name
    description
    deadlineAt
    assignedTo {
      id
      domainId {
        itemId
        tenantId
      }
      data {
        id
        name
        email
        displayName
        initials
      }
    }
    metadata {
      status
      supportsInitiatives {
        id
        domainId {
          itemId
        }
        data {
          id
          name
          tag {
            title
            colorCode
            iconId
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_TABLE__MILESTONE_REPORT = gql`
  fragment MilestoneTable__MilestoneReport on MilestoneReport {
    stats {
      completed
      overdue
      upcoming
    }
    id
    domainId {
      itemId
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_TABLE__INITIATIVE_DETAILED_REPORT_RESPONSE = gql`
  fragment MilestoneTable__InitiativeDetailedReportResponse on InitiativeDetailedReportResponse {
    id
    milestones {
      ...MilestoneTable__MilestoneReport
    }
    allSubInitiatives {
      milestones {
        ...MilestoneTable__MilestoneReport
      }
    }
  }
`;
